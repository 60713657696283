<template>
  <div class="aliPay" v-html="content">
    {{content}}
  </div>
</template>
<script>
export default {
  name: 'AliPay',
  data(){
    return {
      content: ''
    }
  },
  mounted(){
    let form = this.$route.query.htmlData;
    this.content = form;

    this.$nextTick(()=> {
        document.getElementById('alipay_submit').submit();
    })
  },
  methods: {
    
  }
}
</script>
<style scoped lang="scss">

</style>